import {createStyles, makeStyles, Theme} from '@material-ui/core'
import {smBreakpoint} from '../../../utils/constants/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: 'white',
            border: '1px solid',
            borderColor: theme.palette.primary.main,
            borderRadius: '5px',
            padding: '14px 35px',
            width: 130,

            [theme.breakpoints.down(smBreakpoint)]: {
                width: '100%'
            },
        },
        text: {
            color: theme.palette.primary.main,
            fontSize: '1rem',
            textTransform: 'none'
        }
    }),
)

export default useStyles
