import {createStyles, makeStyles, Theme} from '@material-ui/core'
import {smBreakpoint, xsBreakpoint} from '../../../utils/constants/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: '#F8F8F8',
            paddingBottom: '200px',

            [`& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline`]: {
                borderColor: 'inherit'
            }
        },
        backButton: {
            width: '40%',
            display: 'flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: theme.palette.secondary.main,
            fontFamily: 'RobotoBold',

            [theme.breakpoints.down(smBreakpoint)]: {
                position: 'absolute',
                top: '10px'
            },
        },
        backButtonIcon: {
            color: theme.palette.secondary.main,
            fontSize: '1.25rem'
        },
        header: {
            display: 'flex',
            alignItems: 'center'
        },
        rootTitle: {
            fontFamily: 'RobotoBold',
            width: '60%',

            [theme.breakpoints.down(smBreakpoint)]: {
                width: '100%',
                textAlign: 'center',
                marginTop: '50px',
                fontSize: '1.5rem'
            },
        },
        card: {
            border: '1px solid #E5E5E5',
            borderRadius: '4px',
            backgroundColor: 'white',
            boxShadow: '0 2px 5px 1px rgba(0,0,0,0.1)',
            padding: '30px'
        },
        formWrapper: {
            display: 'flex',
            flexDirection: 'column'
        },
        row: {
            display: 'flex',

            [`& .MuiTextField-root:last-child`]: {
                marginRight: 0
            },

            [theme.breakpoints.down(smBreakpoint)]: {
                flexDirection: 'column'
            },
        },
        input: {
            width: '33%',
            margin: '10px 10px 0 0',

            [`& label`]: {
                fontFamily: 'Roboto',
                fontSize: '1rem',
                color: 'rgba(51,51,51,0.5)'
            },

            [`& fieldset`]: {
                borderColor: '#E5E5E5',

                [`& span`]: {
                    fontSize: '0.75rem',
                    fontFamily: 'RobotoBold',
                    color: theme.palette.secondary.main,
                    textTransform: 'uppercase'
                }
            },

            [theme.breakpoints.down(smBreakpoint)]: {
                width: '100%'
            },
        },
        fullInput: {
          width: '100%'
        },
        doubleInput: {
            width: '67%',

            [theme.breakpoints.down(smBreakpoint)]: {
                width: '100%'
            },
        },
        miniInput: {
            width: '16%',

            [theme.breakpoints.down(smBreakpoint)]: {
                width: '100%'
            },
        },
        checkboxWrapper: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '-10px',

            [`& svg`]: {
                width: '14px',
                height: '14px'
            },

            [theme.breakpoints.down(smBreakpoint)]: {
                alignItems: 'flex-start',
                marginBottom: '20px'
            },
        },
        h3: {
            fontFamily: 'RobotoBold',
            color: theme.palette.secondary.main,
            fontSize: '1.5rem',
            marginTop: '30px',
            marginBottom: '15px'
        },
        label: {
            fontFamily: 'Roboto',
            fontSize: '1rem',
            color: theme.palette.secondary.main,
        },
        labelBold: {
            fontFamily: 'RobotoBold',
            fontSize: '1rem',
            color: theme.palette.secondary.main,
        },
        deliveryAddressFormWrapper: {
            backgroundColor: 'rgba(146,39,143,0.02)',
            border: '1px solid #702588',
            borderRadius: '4px',
            padding: '10px 20px 20px 20px'
        },
        infoWrapper: {
            borderRadius:'4px',
            backgroundColor: '#F4E9F3'
        },
        infoMessage: {
            fontFamily: 'RobotoBold',
            color: theme.palette.primary.main,
            fontSize: '0.875rem',
            padding: '30px'
        },
        boldLink: {
            color: theme.palette.secondary.main,
            fontFamily: 'RobotoBold',
            marginLeft: '5px',
            textDecoration: 'none'
        },
        link: {
            color: theme.palette.primary.main,
            marginLeft: '5px',
            textDecoration: 'none',
            cursor: 'pointer'
        },
        partnerLink: {
            [theme.breakpoints.down(smBreakpoint)]: {
                marginLeft: 0
            },
        },
        partnerWrapper: {
            marginBottom: '30px'
        },
        fullRow: {
            display: 'flex',
            width: '100%',
            paddingTop: '3px'
        },
        selectWrapper: {
            width: '33%',
            margin: '10px 10px 0 0',

            [`& button`]: {
                marginRight: '10px',
                width: '48%',
                height: '59px'
            },

            [`& button:last-child`]: {
                marginRight: 0,
            },

            [theme.breakpoints.down(smBreakpoint)]: {
                width: '100%'
            },
        },
        buttonActive: {
            backgroundColor: 'rgba(146,39,143,0.1) !important'
        },
        autocomplete: {
            margin: '10px 0 0 0'
        }
    }),
)

export default useStyles
