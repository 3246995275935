import React, {useContext, useEffect, useState} from 'react'
import {Checkbox, Container, TextField, Typography, FormControlLabel} from '@material-ui/core'
import Autocomplete, { createFilterOptions }  from '@material-ui/lab/Autocomplete'
import clsx from 'clsx'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import * as yup from 'yup'
import {FormikProvider, useFormik} from 'formik'
import {Link} from 'react-router-dom'
import I18nContext from '../../../services/I18n/I18nContext'
import useStyles from './FormStyles'
import {ActionData, QuoteAction, QuoteContext} from '../../../services/quote/QuoteProvider'
import DeliveryAddressForm from './components/DeliveryAddressForm'
import CustomButton from '../../common/CustomButton/CustomButton'
import {partitaValidation, cfValidation} from '../../../utils/helpers/helpers'
import {default as regions} from '../../../services/data/regions.json'

const phoneRegExp = /^(\+)[0-9]{1,3}[0-9]{4,14}(?:x.+)?$/

const formInit = {
    fiscalCode: '',
    partitaIva: '',
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    email: '',
    address: '',
    region: '',
    zip: '',
    province: '',
    code: '',
    deliveryFirstName: '',
    deliveryLastName: '',
    deliveryCompany: '',
    deliveryNote: '',
    deliveryAddress: '',
    deliveryPlace: '',
    deliveryZip: '',
    partnerCode: '',
    deliveryAddressChecked: false,
    terms: false,
    informed: false
}

const Form = () => {

    const classes = useStyles()
    const {translate} = useContext(I18nContext)
    const {dispatch, quote} = useContext(QuoteContext)
    const [showPartnerInput, setShowPartnerInput] = useState(false)
    const [codeType, setCodeType] = useState(quote.codeType)
    const [isPartitaValid, setIsPartitaValid] = useState(quote.isFormValid)
    const [isCFValid, setIsCFValid] = useState(quote.isFormValid)
    const [region, setRegion] = useState({} as any)
    const [hasMultipleZipCodes, setHasMultipleZipCodes] = useState(false)
    const [deliveryCompanyRequired, setDeliveryCompanyRequired] = useState(false)

    const formSchema = yup.object().shape({
        fiscalCode: yup.string().required(translate('requiredFiled')),
        partitaIva: yup.string().required(translate('requiredFiled')),
        province: yup.string().required(translate('requiredFiled')),
        firstName: yup.string().required(translate('requiredFiled')),
        lastName: yup.string().required(translate('requiredFiled')),
        company: yup.string().required(translate('requiredFiled')),
        phone: yup.string().min(11, translate('phoneValidation')).matches(phoneRegExp, translate('phoneValidation')),
        email: yup.string().email(translate('invalidEmail')).required(translate('requiredFiled')),
        address: yup.string().required(translate('requiredFiled')),
        region: yup.string().required(translate('requiredFiled')),
        zip: yup.number().required(translate('requiredFiled')).typeError(translate('requiredFiled')),
        code: codeType === 'sdi' ? yup.string().required(translate('requiredFiled')).matches(
            /^[a-z0-9]+$/i,
            'Il massimo di caratteri e\' 7 e sono consentiti lettere e numeri'
        ).max(7, 'Il massimo di caratteri e\' 7 e sono consentiti lettere e numeri') : yup.string().email(translate('invalidEmail')).required(translate('requiredFiled')),
        deliveryAddressChecked: yup.bool(),
        partnerCode: yup.string(),
        terms: yup.bool().oneOf([true], 'Must agree to something'),
        informed: yup.bool(),
        deliveryFirstName: yup.string(),
        /*.when(['deliveryCompany', 'deliveryAddressChecked'], {
            is: (deliveryCompany: string, deliveryAddressChecked: boolean) => (deliveryCompany && deliveryCompany.length < 1) && deliveryAddressChecked,
            then: yup.string().required('Please enter one of the three fields')
        }),*/
        deliveryLastName: yup.string(),/*.when(['deliveryCompany', 'deliveryAddressChecked'], {
            is: (deliveryCompany: string, deliveryAddressChecked: boolean) => (deliveryCompany && deliveryCompany.length < 1) && deliveryAddressChecked,
            then: yup.string().required('Please enter one of the three fields')
        }),*/
        deliveryCompany: yup.string(),/*.when(['deliveryAddressChecked'], {
            is: (deliveryAddressChecked: boolean) =>  deliveryCompanyRequired && deliveryAddressChecked,
            then: yup.string().required('Please enter one of the three fields')
        }),*/
        deliveryProvince: yup.string().when('deliveryAddressChecked', {
            is: true,
            then: s => s.required(translate('requiredFiled'))
        }),
        deliveryAddress: yup.string().when('deliveryAddressChecked', {
            is: true,
            then: s => s.required(translate('requiredFiled'))
        }),
        deliveryPlace: yup.string().when('deliveryAddressChecked', {
            is: true,
            then: s => s.required(translate('requiredFiled'))
        }),
        deliveryZip: yup.number().when('deliveryAddressChecked', {
            is: true,
            then: s => s.required(translate('requiredFiled'))
        }),
    }).test(
        'partitaIva & CF',
        'Partita or CF not valid',
        (values) => {
            const partitvaValid = partitaValidation(values.partitaIva || '')
            setIsPartitaValid(partitvaValid)
            const CFValid = cfValidation(values.fiscalCode || '') || partitaValidation(values.fiscalCode || '')
            setIsCFValid(CFValid)

            const firstName = values.deliveryFirstName || ''
            const lastName = values.deliveryLastName || ''
            setDeliveryCompanyRequired(firstName.length < 1 && lastName.length < 1)

            return CFValid
        })

    const formik = useFormik({
        initialValues: quote.form || formInit,
        validationSchema: formSchema,
        onSubmit: values => {
            console.log(values)
        }
    })

    const {
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isValid,
        dirty,
        handleBlur,
        setFieldValue
    } = formik

    const [currentRegion, setCurrentRegion] = useState(values.region)

    const filterOptions = createFilterOptions({
        matchFrom: 'start',
    });

    useEffect(() => {
        setFieldValue('region', values.region)
        setFieldValue('province', values.province)
        setFieldValue('zip', values.zip)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        dispatch({
            type: QuoteAction.setForm,
            data: {form: values} as ActionData
        })
        // eslint-disable-next-line
    }, [values])

    useEffect(() => {
        if(currentRegion.length > 1) return

        setFieldValue('region', '')
        // eslint-disable-next-line
    }, [currentRegion])

    /*useEffect(() => {
        if (!values.deliveryAddressChecked) return

        setFieldValue('deliveryCompany', values.company)
        // eslint-disable-next-line
    }, [values.deliveryAddressChecked])*/

    useEffect(() => {
        dispatch({
            type: QuoteAction.setCodeType,
            data: {codeType: codeType} as ActionData
        })
        // eslint-disable-next-line
    }, [codeType])


    useEffect(() => {
        dispatch({
            type: QuoteAction.setIsFormValid,
            data: {isFormValid: (quote.formDirty ? isValid : (dirty && isValid)) && isPartitaValid && isCFValid} as ActionData
        })
        // eslint-disable-next-line
    }, [isValid, isPartitaValid, isCFValid])

    const handleRegionChange = (val: any) => {
        if(val) {
            setFieldValue('region', val.nome)
            setFieldValue('province', val.sigla)
            setFieldValue('zip', val.cap[0])
            setHasMultipleZipCodes(val.cap.length > 1)
        } else {
            setFieldValue('region', '')
            setFieldValue('province', '')
            setFieldValue('zip', '')
            setHasMultipleZipCodes(false)
        }

        setTimeout(() => {
            formik.setFieldTouched('region', true)
            formik.setFieldTouched('province', true)
            formik.setFieldTouched('zip', true)
        });

        setRegion(val)
        setCurrentRegion(val ? val.nome : '')
    }

    return (
        <Container className={classes.root}>
            <div className={classes.header}>
                <Link to='/' className={classes.backButton}>
                    <ArrowBackIosIcon className={classes.backButtonIcon}/>
                    {translate('back')}
                </Link>
                <h1 className={classes.rootTitle}>{translate('formTitle')}</h1>
            </div>

            <div className={classes.card}>
                <Typography className={classes.h3} variant='h3'>{translate('formSubtitle')}</Typography>
                <FormikProvider value={formik} key='stef'>
                    <form onSubmit={handleSubmit} name='quoteForm' className={classes.formWrapper}>
                        <div className={classes.row}>
                            <TextField
                                className={classes.input}
                                variant='outlined'
                                name='company'
                                onBlur={handleBlur}
                                label={`${translate('company')} *`}
                                value={values.company}
                                onChange={handleChange}
                                error={touched.company && Boolean(errors.company)}
                                helperText={touched.company && errors.company}
                            />
                            <TextField
                                className={classes.input}
                                variant='outlined'
                                name='partitaIva'
                                onBlur={handleBlur}
                                label={`${translate('partitaIVA')} *`}
                                value={values.partitaIva}
                                onChange={(e) => {
                                    handleChange(e)
                                    let val = e.target.value
                                    const isValid = partitaValidation(val)
                                    val.length > 0 && isValid && setFieldValue('fiscalCode', e.target.value)
                                }}
                                error={touched.partitaIva && (Boolean(errors.partitaIva) || !isPartitaValid)}
                                helperText={touched.partitaIva && errors.partitaIva}
                            />
                            <TextField
                                className={classes.input}
                                variant='outlined'
                                name='fiscalCode'
                                onBlur={handleBlur}
                                label={`${translate('fiscalCode')} *`}
                                value={values.fiscalCode}
                                onChange={handleChange}
                                error={touched.fiscalCode && (Boolean(errors.fiscalCode) || !isCFValid)}
                                helperText={touched.fiscalCode && errors.fiscalCode}
                            />
                        </div>
                        <div className={classes.row}>
                            <TextField
                                className={classes.input}
                                variant='outlined'
                                name='firstName'
                                onBlur={handleBlur}
                                label={`${translate('firstName')} *`}
                                value={values.firstName}
                                onChange={handleChange}
                                error={touched.firstName && Boolean(errors.firstName)}
                                helperText={touched.firstName && errors.firstName}
                            />
                            <TextField
                                className={classes.input}
                                variant='outlined'
                                name='lastName'
                                onBlur={handleBlur}
                                label={`${translate('lastName')} *`}
                                value={values.lastName}
                                onChange={handleChange}
                                error={touched.lastName && Boolean(errors.lastName)}
                                helperText={touched.lastName && errors.lastName}
                            />
                            <TextField
                                className={classes.input}
                                variant='outlined'
                                name='phone'
                                onBlur={handleBlur}
                                label={`${translate('phone')} *`}
                                value={values.phone}
                                onChange={handleChange}
                                error={Boolean(errors.phone)}
                                helperText={errors.phone}
                            />
                        </div>
                        <div className={classes.row}>
                            <TextField
                                className={classes.input}
                                variant='outlined'
                                name='email'
                                onBlur={handleBlur}
                                label={`${translate('email')} *`}
                                value={values.email}
                                onChange={handleChange}
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                            />
                            <Autocomplete
                                id='regions-select'
                                options={regions}
                                filterOptions={filterOptions}
                                onChange={(event: any, newValue: any | null) => {
                                    handleRegionChange(newValue)
                                }}
                                inputValue={currentRegion}
                                getOptionLabel={(option: any) => option.nome}
                                className={classes.input}
                                renderInput={(params) => <TextField {...params} variant='outlined'
                                                                    autoComplete='new-password'
                                                                    onChange={(e) => setCurrentRegion(e.target.value)}
                                                                    label={`${translate('region')} *`}/>}
                            />
                            <TextField
                                className={clsx(classes.miniInput, classes.input)}
                                variant='outlined'
                                name='province'
                                onBlur={handleBlur}
                                disabled={values.province.length > 0}
                                label={`${translate('province')} *`}
                                value={values.province}
                                autoComplete='new-password'
                                onChange={handleChange}
                                error={touched.province && Boolean(errors.province)}
                                helperText={touched.province && errors.province}
                            />
                            {
                                (region && hasMultipleZipCodes) ? (
                                    <Autocomplete
                                        id='zip-select'
                                        filterOptions={filterOptions}
                                        options={region.cap}
                                        onChange={(event: any, newValue: any | null) => {
                                            setFieldValue('zip', newValue || '')
                                        }}
                                        defaultValue={values.zip}
                                        className={clsx(classes.miniInput, classes.input)}
                                        classes={{
                                            root: classes.autocomplete
                                        }}
                                        renderInput={(params) => <TextField {...params} variant='outlined'
                                                                            autoComplete='new-password'
                                                                            label={`${translate('zip')} *`}/>}
                                    />
                                ) : (
                                    <TextField
                                        className={clsx(classes.miniInput, classes.input)}
                                        variant='outlined'
                                        name='zip'
                                        onBlur={handleBlur}
                                        disabled={values.zip.length > 0}
                                        label={`${translate('zip')} *`}
                                        value={values.zip}
                                        onChange={handleChange}
                                        autoComplete='new-password'
                                        error={touched.zip && Boolean(errors.zip)}
                                        helperText={touched.zip && errors.zip}
                                    />
                                )
                            }
                        </div>
                        <div className={classes.row}>
                            <TextField
                                className={classes.input}
                                variant='outlined'
                                name='address'
                                onBlur={handleBlur}
                                label={`${translate('address')} *`}
                                value={values.address}
                                onChange={handleChange}
                                error={touched.address && Boolean(errors.address)}
                                helperText={touched.address && errors.address}
                            />
                            <div className={classes.selectWrapper}>
                                <CustomButton text={translate('sdi')}
                                              onClick={() => {
                                                  setCodeType('sdi')
                                                  setFieldValue('code', '')
                                              }}
                                              disabled={false}
                                              className={codeType === 'sdi' ? classes.buttonActive : ''}
                                />
                                <CustomButton text={translate('pec')}
                                              onClick={() => {
                                                  setCodeType('pec')
                                                  setFieldValue('code', '')
                                              }}
                                              className={codeType === 'pec' ? classes.buttonActive : ''}
                                />
                            </div>
                            <TextField
                                className={classes.input}
                                variant='outlined'
                                name='code'
                                type={codeType === 'sdi' ? 'text' : 'email'}
                                onBlur={handleBlur}
                                label={`${translate(codeType === 'sdi' ? 'sdi' : 'pec')} *`}
                                value={values.code}
                                onChange={handleChange}
                                error={touched.code && Boolean(errors.code)}
                                helperText={touched.code && errors.code}
                            />
                        </div>
                        <div className={classes.checkboxWrapper} style={{marginTop: '30px', paddingLeft: 10}}>
                            <FormControlLabel
                                control={
                                    <Checkbox value={values.deliveryAddressChecked}
                                              onChange={handleChange}
                                              name='deliveryAddressChecked'
                                              defaultChecked={values.deliveryAddressChecked}
                                              color='primary'/>
                                }
                                classes={{label: classes.labelBold}}
                                label={translate('deliveryAddress')}
                            />
                        </div>
                        {values.deliveryAddressChecked && (
                            <div className={classes.deliveryAddressFormWrapper}>
                                <DeliveryAddressForm formik={formik}/>
                            </div>
                        )}
                        <Typography className={classes.h3} variant='h3'>{translate('consent')}</Typography>
                        <div className={classes.checkboxWrapper}>
                            <Checkbox value={values.terms} onChange={handleChange} name='terms' color='primary'
                                      defaultChecked={values.terms}/>
                            <div style={{width: '100%'}}>
                                <Typography className={classes.label} style={{display: 'inline'}}>
                                    {translate('accept')}
                                </Typography>
                                <a href='https://www.marketino.it/condizioni-di-utilizzo-del-servizio'
                                   className={classes.boldLink} target='_blank'
                                   rel='noreferrer'>
                                    {translate('terms')}
                                </a>
                                <span style={{marginLeft: 5}}>*</span>
                            </div>
                        </div>
                        <div className={classes.checkboxWrapper} style={{paddingLeft: 10}}>
                            <FormControlLabel
                                control={
                                    <Checkbox value={values.informed}
                                              defaultChecked={values.informed}
                                              onChange={handleChange}
                                              name='informed'
                                              color='primary'/>
                                }
                                label={translate('informed')}
                            />
                        </div>
                        <div className={classes.fullRow}>
                            <Typography className={classes.label}>
                                {translate('privacyInfo')}
                                <span>
                                    <a href='https://www.marketino.it/informativa-sulla-privacy'
                                       className={classes.link} target='_blank' rel='noreferrer'>
                                        {translate('here')}
                                    </a>
                                </span>
                            </Typography>
                        </div>
                        {/*<Typography className={classes.h3} variant='h3'>{translate('partners')}</Typography>
                        <div className={classes.partnerWrapper}>
                            <div className={classes.row}>
                                <Typography className={classes.label}>{translate('partnerMessage')}</Typography>
                                <Typography className={clsx(classes.link, classes.partnerLink)}
                                            onClick={() => setShowPartnerInput(true)}>{translate('partnerMessageLink')}</Typography>
                            </div>
                            {showPartnerInput && (
                                <TextField
                                    className={classes.input}
                                    variant='outlined'
                                    name='partnerCode'
                                    onBlur={handleBlur}
                                    label={translate('partnerCode')}
                                    value={values.partnerCode}
                                    onChange={handleChange}
                                    error={touched.partnerCode && Boolean(errors.partnerCode)}
                                    helperText={touched.partnerCode && errors.partnerCode}
                                />
                            )}
                        </div>*/}
                    </form>
                </FormikProvider>
                <div className={classes.infoWrapper} style={{marginTop: 20}}>
                    <Typography className={classes.infoMessage}>
                        {translate('formInfoMessage')}
                    </Typography>
                </div>
            </div>
        </Container>
    )
}

export default Form
