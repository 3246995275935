import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import App from './App'

declare var process : {
    env: {
        REACT_APP_GTM_ID: string
    }
}

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID
}

TagManager.initialize(tagManagerArgs)

declare global {
    interface Window {
        dataLayer: any
    }
}

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);
