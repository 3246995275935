import React, {useContext} from 'react'
import {Typography} from '@material-ui/core'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useStyles from '../NotificationStyles'
import I18nContext from '../../../../services/I18n/I18nContext'

const SuccessButton = () => {

    const classes = useStyles()
    const {translate} = useContext(I18nContext)

    const onButtonClick = () => {
        window.location.href = 'https://www.marketino.it/'
    }

    return (
            <Typography className={classes.button} onClick={onButtonClick}>
                {translate('backToHome')}
                <ArrowForwardIosIcon className={classes.buttonIcon}/>
            </Typography>
    )
}

export default SuccessButton

