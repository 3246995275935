import React, {useContext} from 'react'
import {TextField} from '@material-ui/core'
import I18nContext from '../../../../services/I18n/I18nContext'
import useStyles from '../FormStyles'
import clsx from 'clsx';

interface IDeliveryAddressFormProps {
    formik: any
}

const DeliveryAddressForm = (props: IDeliveryAddressFormProps) => {

    const classes = useStyles()
    const {translate} = useContext(I18nContext)

    const {
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
    } = props.formik

    return (
        <>
            <div className={classes.row}>
                <TextField
                    className={classes.input}
                    variant='outlined'
                    name='deliveryFirstName'
                    onBlur={handleBlur}
                    label={translate('deliveryFirstName')}
                    value={values.deliveryFirstName}
                    onChange={handleChange}
                    error={touched.deliveryFirstName && Boolean(errors.deliveryFirstName)}
                    helperText={touched.deliveryFirstName && errors.deliveryFirstName}
                />
                <TextField
                    className={classes.input}
                    variant='outlined'
                    name='deliveryLastName'
                    onBlur={handleBlur}
                    label={translate('deliveryLastName')}
                    value={values.deliveryLastName}
                    onChange={handleChange}
                    error={touched.deliveryLastName && Boolean(errors.deliveryLastName)}
                    helperText={touched.deliveryLastName && errors.deliveryLastName}
                />
                <TextField
                    className={classes.input}
                    variant='outlined'
                    name='deliveryPlace'
                    onBlur={handleBlur}
                    label={`${translate('deliveryPlace')} *`}
                    value={values.deliveryPlace}
                    onChange={handleChange}
                    error={touched.deliveryPlace && Boolean(errors.deliveryPlace)}
                    helperText={touched.deliveryPlace && errors.deliveryPlace}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    className={clsx(classes.doubleInput, classes.input)}
                    variant='outlined'
                    name='deliveryCompany'
                    onBlur={handleBlur}
                    label={translate('deliveryCompany')}
                    value={values.deliveryCompany}
                    onChange={handleChange}
                    error={touched.deliveryCompany && Boolean(errors.deliveryCompany)}
                    helperText={touched.deliveryCompany && errors.deliveryCompany}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    className={classes.input}
                    variant='outlined'
                    name='deliveryProvince'
                    onBlur={handleBlur}
                    label={`${translate('deliveryProvince')} *`}
                    value={values.deliveryProvince}
                    onChange={handleChange}
                    error={touched.deliveryProvince && Boolean(errors.deliveryProvince)}
                    helperText={touched.deliveryProvince && errors.deliveryProvince}
                />
                <TextField
                    className={classes.input}
                    variant='outlined'
                    name='deliveryZip'
                    onBlur={handleBlur}
                    label={`${translate('deliveryZip')} *`}
                    value={values.deliveryZip}
                    onChange={handleChange}
                    error={touched.deliveryZip && Boolean(errors.deliveryZip)}
                    helperText={touched.deliveryZip && errors.deliveryZip}
                />
                <TextField
                    className={classes.input}
                    variant='outlined'
                    name='deliveryAddress'
                    onBlur={handleBlur}
                    label={`${translate('deliveryAddress')} *`}
                    value={values.deliveryAddress}
                    onChange={handleChange}
                    error={touched.deliveryAddress && Boolean(errors.deliveryAddress)}
                    helperText={touched.deliveryAddress && errors.deliveryAddress}
                />
            </div>
            <div className={classes.row}>
                <TextField
                    className={clsx(classes.fullInput, classes.input)}
                    variant='outlined'
                    name='deliveryNote'
                    onBlur={handleBlur}
                    multiline={true}
                    rows={3}
                    label={translate('deliveryNote')}
                    value={values.deliveryNote}
                    onChange={handleChange}
                    error={touched.deliveryNote && Boolean(errors.deliveryNote)}
                    helperText={touched.deliveryNote && errors.deliveryNote}
                />
            </div>
        </>
    )
}

export default DeliveryAddressForm
