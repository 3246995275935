import RoutesList from './RoutesList'
import Services from '../components/containers/Services/Services';
import Form from '../components/containers/Form/Form';

const Routes = [
  {
    path: RoutesList.SERVICES,
    exact: true,
    component: Services,
  },
  {
    path: RoutesList.FORM,
    exact: true,
    component: Form,
  },
]

export default Routes
