import React, {useContext, useState} from 'react'
import {Container, List} from '@material-ui/core'
import I18nContext from '../../../services/I18n/I18nContext'
import servicesData from '../../../services/data/services.json'
import IServices from '../../../interfaces/IServices'
import ServiceCategoryItem from './components/ServiceCategoryItem'
import useStyles from './ServicesStyles'
import {ActionData, QuoteAction, QuoteContext} from '../../../services/quote/QuoteProvider'

const Services = () => {
    const {translate} = useContext(I18nContext)
    const classes = useStyles()
    const {dispatch, quote} = useContext(QuoteContext)
    const [filteredServiceData, setFilteredServiceData] = useState(servicesData)

    const addItem = (data: ActionData) => {
        dispatch({
            data,
            type: QuoteAction.addItem,
        })
    }

    const removeItem = (data: ActionData) => {
        dispatch({
            data,
            type: QuoteAction.removeItem,
        })
    }

    const changeItem = (data: ActionData) => {
        const serviceDataCopy = JSON.parse(JSON.stringify(servicesData));
        const basicServiceCopy: ActionData = serviceDataCopy[0].data[data.id === 'basicService1' ? 1 : 0];
        serviceDataCopy[0].data[0].quantity = data.quantity
        serviceDataCopy[0].data[1].quantity = data.quantity;

        setFilteredServiceData(serviceDataCopy)

        dispatch({
            data: basicServiceCopy,
            type: QuoteAction.changeItem,
        })

        dispatch({
            data,
            type: QuoteAction.changeItem,
        })
    }

    const setUseType = (useType: string) => {
        dispatch({
            data: {useType} as ActionData,
            type: QuoteAction.setUseType,
        })
    }

    return (
        <Container className={classes.root}>
            <h1 className={classes.rootTitle}>{translate('title')}</h1>
            <List>
                {
                    (filteredServiceData as IServices[]).map((serviceCategory, index) => {
                        return (
                            <ServiceCategoryItem key={`serviceCategoryItem${index}`}
                                                 serviceNumber={index + 1}
                                                 quote={quote}
                                                 data={serviceCategory}
                                                 setUseType={setUseType}
                                                 addItem={addItem}
                                                 changeItem={changeItem}
                                                 removeItem={removeItem}/>
                        )
                    })
                }
            </List>
        </Container>
    )
}

export default Services
