import React, {useContext} from 'react'
import {Container, Typography} from '@material-ui/core'
import useStyles from './NotificationStyles'
import I18nContext from '../../../services/I18n/I18nContext'

interface INotificationProps {
    message: string
    button: any
    title: string
    icon: any
}

const Notification = (props: INotificationProps) => {

    const classes = useStyles()
    const {translate} = useContext(I18nContext)

    return (
        <Container className={classes.root}>
            <Typography variant='h1' className={classes.title}>{translate(props.title)}</Typography>
            <div className={classes.card}>
                {props.icon}
                <Typography className={classes.message}>{translate(props.message)}</Typography>
                {props.button}
            </div>
        </Container>
    )
}

export default Notification
