import React from 'react'
import {MenuItem, Select, Theme, withStyles, createStyles, InputBase} from '@material-ui/core'

interface ICustomButtonProps {
    quantity: number,
    maxQuantity: number,
    handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void,
    minValue: number
}

const BootstrapInput = withStyles((theme: Theme) =>
    createStyles({
        root: {
            'label + &': {
                marginTop: theme.spacing(3),
            },
        },
        input: {
            borderRadius: 4,
            position: 'relative',
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #ced4da',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            fontSize: 16,
            outline: 'none',
            padding: '16px 24px',
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            fontFamily: [
             "Roboto"
            ].join(','),
            '&:focus': {
                borderRadius: 4,
                backgroundColor: 'transparent'
            },
        },
    }),
)(InputBase);

const QuantitySelector: React.FunctionComponent<ICustomButtonProps> = props => {

    let list = Array.from(Array(props.maxQuantity + 1), (_, x) => x);

    list = props.minValue > 0 ? list.slice(props.minValue) : list

    return (
        <div>
            <Select
                labelId='quantityLabel'
                id='quantity'
                value={props.quantity}
                onChange={props.handleChange}
                input={<BootstrapInput/>}
            >
                {list.map(i => {
                    return (
                        <MenuItem key={`quantitySelect${i}`} value={i}>{i}</MenuItem>
                    )
                })}
            </Select>
        </div>
    )

}

export default QuantitySelector
