import React from 'react'
import {ListItem} from '@material-ui/core'
import IServices from '../../../../interfaces/IServices'
import IService from '../../../../interfaces/IService'
import ServiceItem from './ServiceItem'
import useStyles from '../ServicesStyles'
import {ActionData, Quote} from '../../../../services/quote/QuoteProvider'

interface IServiceCategoryItemProps {
    data: IServices,
    serviceNumber: number,
    quote: Quote,
    addItem: (item: ActionData) => void,
    removeItem: (item: ActionData) => void,
    changeItem: (item: ActionData) => void,
    setUseType: (useType: string) => void
}

const ServiceCategoryItem: React.FunctionComponent<IServiceCategoryItemProps> = props => {
    const {data, addItem, removeItem, changeItem, setUseType, quote} = props
    const classes = useStyles()

    return (
        <ListItem className={classes.serviceWrapper}>
            <div className={classes.serviceItemWrapper}>
                {
                    (data.data as IService[]).map((service, index) => {
                        const selectedItem = quote.items.find(i => i.id === service.id)
                        return (
                            <ServiceItem key={`serviceItem${index}`}
                                         data={service}
                                         useType={quote.useType}
                                         selectedItem={selectedItem}
                                         resetItem={false}
                                         addItem={addItem}
                                         changeItem={changeItem}
                                         setUseType={setUseType}
                                         removeItem={removeItem}/>
                        )
                    })
                }
            </div>
        </ListItem>
    )
}

export default ServiceCategoryItem
