const validateISO7064 = (oibArray: any) => {
    const controlDigit = oibArray.pop();
    let value = oibArray.reduce((prev: any, next: any) => {
        let check = prev + next;
        check %= 10;

        if (check === 0) {
            check = 10;
        }

        check *= 2;
        check %= 11;

        return check;
    }, 10);

    const checksum = (11 - value === 10) ? 0 : (11 - value);

    return checksum === controlDigit;
}

const stringToNumberArray = (string: any) => {
    return Array.prototype.slice.call(string).map((digit) => {
        return parseInt(digit, 10);
    });
}

export const validate = (oibNumber: any) => {
    const number = oibNumber.toString();
    const oibDigits = stringToNumberArray(number);

    const lengthVal = oibDigits.length === 11;
    const intVal = parseInt(number, 10);
    const isoVal = validateISO7064(oibDigits);

    return lengthVal && Boolean(intVal) && isoVal;
}

/**
 * @param pi
 */

export const partitaValidation = (pi: string) => {
    if (pi === '') return false
    if (!/^[0-9]{11}$/.test(pi)) return false
    let s = 0
    for (let i = 0; i <= 9; i += 2) s += pi.charCodeAt(i) - '0'.charCodeAt(0)
    for (let i = 1; i <= 9; i += 2) {
        var c = 2 * (pi.charCodeAt(i) - '0'.charCodeAt(0))
        if (c > 9) c = c - 9
        s += c
    }
    var atteso = (10 - (s % 10)) % 10
    if (atteso !== pi.charCodeAt(10) - '0'.charCodeAt(0))
        return false
    return true
}

/**
 * @param cf
 */
export const cfValidation = (cf: string) => {
    cf = cf.toUpperCase()
    if (cf === '') return false
    if (!/^[0-9A-Z]{16}$/.test(cf))
        return false
    var map = [
        1,
        0,
        5,
        7,
        9,
        13,
        15,
        17,
        19,
        21,
        1,
        0,
        5,
        7,
        9,
        13,
        15,
        17,
        19,
        21,
        2,
        4,
        18,
        20,
        11,
        3,
        6,
        8,
        12,
        14,
        16,
        10,
        22,
        25,
        24,
        23,
    ]
    var s = 0
    for (var i = 0; i < 15; i++) {
        var c = cf.charCodeAt(i)
        if (c < 65) c = c - 48
        else c = c - 55
        if (i % 2 === 0) s += map[c]
        else s += c < 10 ? c : c - 10
    }
    var atteso = String.fromCharCode(65 + (s % 26))
    if (atteso !== cf.charAt(15))
        return false
    return true
}
