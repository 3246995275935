import React, {useState} from 'react'
import I18nContext from './I18nContext'
import {Language, translations} from './Translation'
import {trk} from './Translate'

export const I18nProvider: React.FC = ({children}) => {
    const [lang, setLang] = useState<Language>(Language.HR)
    const translate = (key: trk, strict?: boolean) => translations[lang][key] || (strict ? undefined : key)

    return (
        <I18nContext.Provider value={{lang, setLang, translate, Language}}>
            {children}
        </I18nContext.Provider>
    )
}
export default I18nProvider
