import {createContext} from 'react'
import {Language} from './Translation'
import {trk} from './Translate'


const I18nContext = createContext({
    lang: 'hr',
    setLang: (lang: Language) => {},
    translate: (key: trk  , strict?: boolean) => key || undefined,
    Language,
})

export default I18nContext
