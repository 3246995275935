import {createStyles, makeStyles, Theme} from '@material-ui/core'
import {mdBreakpoint, smBreakpoint} from '../../../utils/constants/constants';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: '#F8F8F8',
            paddingBottom: '200px',

            [theme.breakpoints.down(smBreakpoint)]: {
                padding: '0 0 120px 0'
            }
        },
        rootTitle: {
            fontFamily: 'RobotoBold',
            textAlign: 'center',

            [theme.breakpoints.down(smBreakpoint)]: {
                width: '80%',
                textAlign: 'left',
                fontSize: '1.5rem',
                paddingLeft: '20px'
            },
        },
        serviceWrapper: {
            flexDirection: 'column'
        },
        title: {
            color: theme.palette.secondary.main,
            fontFamily: 'RobotoBold',
            fontSize: '1.25rem',
            display: 'inline-block',
            textAlign: 'left',
            textTransform: 'uppercase',
            marginRight: '8px'
        },
        serviceItemWrapper: {
            borderRadius: '4px',
            backgroundColor: 'white',
            border: '1px solid',
            borderColor: theme.palette.secondary.light,
            width: '100%'
        },
        serviceItem: {
            display: 'flex',
            borderBottom: '1px solid',
            borderBottomColor: theme.palette.secondary.light,
            padding: '25px',

            '&:last-child': {
                borderBottom: 0
            },

            [theme.breakpoints.down(smBreakpoint)]: {
                flexDirection: 'column',
                position: 'relative',
                padding: '16px'
            },

            // Item mobile/tablet styles

            '&.item-active': {
                '& $imageWrapper': {
                    padding: '30px 0 0 0'
                },

                '& $image': {
                    maxWidth: 'inherit',
                    width: '100%',
                    height: 210
                },

                '& $serviceHeader': {
                    width: '100%',
                    top: '16px',
                    left: '15px',
                    height: 'inherit'
                },

                '& $serviceItemTitle': {
                    fontSize: '1.2rem'
                },

                '& $serviceItemDescription': {
                    display: 'block'
                }
            }
        },
        imageWrapper: {
            flex: '.1',
            paddingRight: '25px',
        },
        image: {
            width: 135,
            height: 105,
            backgroundSize: 'cover',
            borderRadius: 4,

            [theme.breakpoints.down(smBreakpoint)]: {
                width: 80,
                height: 65,
                transition: 'height .3s ease-in'
            },
        },
        mainWrapper: {
            flex: '.75',
            paddingRight: '15px',

            [theme.breakpoints.down(smBreakpoint)]: {
                marginTop: 15
            },
        },
        actionWrapper: {
            flex: '.15',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            flexDirection: 'column',


            [theme.breakpoints.down(smBreakpoint)]: {
                alignItems: 'flex-start'
            },
        },
        priceButtonWrapper: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
        },
        priceWrapper: {
            textAlign: 'left',
            width: '130px',

            [theme.breakpoints.down(smBreakpoint)]: {
                display: 'flex',
            },

            [theme.breakpoints.down(mdBreakpoint)]: {
                width: '180px'
            },
        },
        toggleButton: {
            textAlign: 'right',
            cursor: 'pointer',

            [theme.breakpoints.up(smBreakpoint)]: {
                display: 'none',
            },
        },
        toggleButtonText: {
            display: 'inline',

            '&:first-child': {
                marginRight: '3px'
            }
        },
        serviceItemTitle: {
            fontSize: '1.25rem',
            fontFamily: 'RobotoBold',
            color: theme.palette.secondary.main,
            margin: 0,
            display: 'inline'
        },
        serviceItemSubTitle: {
            fontSize: '1rem',
            fontFamily: 'Roboto',
            color: theme.palette.secondary.main,
            opacity: 0.7,
            marginBottom: 3
        },
        serviceItemDescription: {
            fontSize: '1rem',
            fontFamily: 'Roboto',
            color: theme.palette.secondary.main,

            [theme.breakpoints.down(smBreakpoint)]: {
                display: 'none'
            },
        },
        addIcon: {
            color: theme.palette.primary.main,
            fontSize: '.8rem',
            marginRight: '5px'
        },
        addedIcon: {
            color: theme.palette.primary.main,
            fontSize: '1.2rem',
            marginRight: '5px'
        },
        buttonActive: {
            backgroundColor: 'rgba(146,39,143,0.1) !important'
        },
        price: {
            fontSize: '1.25rem',
            color: theme.palette.primary.main,
            display: 'inline-block',
            fontFamily: 'RobotoBold',
        },
        previousPrice: {
            fontSize: '0.875rem',
            color: theme.palette.secondary.main,
            textDecoration: 'line-through'
        },
        currency: {
            fontSize: '1.0625rem',
            color: theme.palette.primary.main,
            display: 'inline-block',
            marginLeft: '5px',
            fontFamily: 'RobotoBold'
        },
        actionContainer: {
            display: 'flex',
            alignItems: 'center',

            [theme.breakpoints.down(smBreakpoint)]: {
                width: '100%'
            },
        },
        noButtonRadius: {
            borderTopLeftRadius: '0 !important',
            borderBottomLeftRadius: '0 !important'
        },
        removeButton: {
            color: '#D0021B',
            cursor: 'pointer',
            marginRight: '30px'
        },
        infoTextWrapper: {
            marginLeft: 5,
            display: 'inline'
        },
        infoIcon: {
            display: 'inline-block',
            color: theme.palette.primary.main,
            marginRight: '5px',
            cursor: 'pointer',
            width: '20px',
            height: '20px',

            '&:hover + $infoText': {
                display: 'inline-block'
            },
        },
        tooltip: {
            backgroundColor: '#F4E9F3',
            color: theme.palette.primary.main,
            fontFamily: 'RobotoBold',
            fontSize: '0.875rem',
            padding: '4px 8px',
            borderRadius: '4px',
            display: 'inline-block',
            maxWidth: '100%',

            [theme.breakpoints.down(smBreakpoint)]: {
                maxWidth: 300
            },
        },
        infoText: {
            position: 'absolute',
            left: 250,
            top: 0,
            display: 'none',
            backgroundColor: '#F4E9F3',
            color: theme.palette.primary.main,
            fontFamily: 'RobotoBold',
            fontSize: '0.875rem',
            padding: '4px 8px',
            borderRadius: '4px'

        },
        serviceCategoryHeader: {
            position: 'relative',
            display: 'flex',
            width: '100%',
            marginBottom: '10px',
            alignItems: 'center',
        },
        serviceHeader: {
            position: 'relative',
            display: 'flex',
            width: '100%',
            marginBottom: 0,
            alignItems: 'center',

            [theme.breakpoints.down(smBreakpoint)]: {
                top: '12px',
                left: '120px',
                position: 'absolute',
                width: 'calc(100% - 140px)',
                height: '70px',
                transition: 'left .3s linear'
            },
        },
        useTypeWrapper: {
            padding: '10px 0 25px 180px',

            [`& button`]: {
                width: '120px',
                padding: '14px 0',

                [`&:first-child`]: {
                    borderRight: 'none',
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0
                },

                [`&:last-child`]: {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                },
            },

            [theme.breakpoints.down(smBreakpoint)]: {
                display: 'flex',
                padding: 15
            },
        }
    }),
)

export default useStyles
