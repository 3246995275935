import React, {createContext, Dispatch, useReducer} from 'react'

export interface QuoteItem {
    id: string
    title: string
    subtitle: string
    description: string
    price: number
    previousPrice?: number
    monthlyService: boolean
    paymentDelay: boolean
    fixedPrice?: number
    hasQuantity: boolean
    preselected: boolean
    note?: string
    imageIndex: number
    quantity: number
}

export interface ActionData extends QuoteItem {
    coupon?: string,
    form?: Form,
    isFormValid?: boolean,
    quoteNotification?: string | null,
    useType?: string | null,
    codeType?: string | null
}

export interface Form {
    fiscalCode: string,
    partitaIva: string,
    province: string,
    firstName: string,
    lastName: string,
    company: string,
    phone: string,
    email: string,
    address: string,
    region: string,
    zip: string,
    code: string,
    deliveryFirstName: string,
    deliveryLastName: string,
    deliveryCompany: string,
    deliveryProvince: string,
    deliveryNote: string,
    deliveryAddress: string,
    deliveryPlace: string,
    deliveryZip: string,
    partnerCode: string,
    deliveryAddressChecked: boolean,
    terms: boolean,
    informed: boolean
}

export type Quote = {
    totalFixed: number,
    totalMonthly: number,
    totalFixedPrevious: number,
    totalSavings: number,
    totalMonthlyPrevious: number,
    totalDelay: number,
    items: QuoteItem[],
    coupon?: string,
    form?: Form,
    formDirty: boolean,
    isFormValid?: boolean,
    quoteNotification?: string | null,
    useType?: string,
    codeType?: string
}

const initForm: Form = {
    fiscalCode: '',
    partitaIva: '',
    firstName: '',
    lastName: '',
    company: '',
    phone: '',
    email: '',
    address: '',
    region: '',
    zip: '',
    province: '',
    code: '',
    deliveryFirstName: '',
    deliveryLastName: '',
    deliveryCompany: '',
    deliveryProvince: '',
    deliveryNote: '',
    deliveryAddress: '',
    deliveryPlace: '',
    deliveryZip: '',
    partnerCode: '',
    deliveryAddressChecked: false,
    terms: false,
    informed: false
}

export const initQuote: Quote = {
    totalFixed: 0,
    totalMonthly: 0,
    totalFixedPrevious: 0,
    totalSavings: 0,
    totalMonthlyPrevious: 0,
    totalDelay: 0,
    items: [],
    coupon: '',
    formDirty: false,
    form: initForm,
    isFormValid: false,
    quoteNotification: null,
    useType: 'internal',
    codeType: 'sdi'
}

interface IQuoteDispatch {
    dispatch: Dispatch<IQuoteAction>
    quote: Quote
}

export enum QuoteAction {
    'addItem',
    'removeItem',
    'changeItem',
    'setCoupon',
    'setForm',
    'setIsFormValid',
    'setQuoteNotification',
    'setUseType',
    'setCodeType'
}

export interface IQuoteAction {
    type: QuoteAction
    data: ActionData
}

export const QuoteContext = createContext({
    quote: initQuote,
    dispatch: () => {
    },
} as IQuoteDispatch)

const getTotal = (items: QuoteItem[]) => {
    let totalFixed = 0 //basic service activation
    let totalMonthly = 0
    let totalFixedPrevious = 0

    for(let i = 0; i < items.length; i++) {
        const item = items[i]
        if(!item.monthlyService && !item.paymentDelay){
            totalFixed = totalFixed + (item.price * item.quantity)
            totalFixedPrevious = totalFixedPrevious + ((item.previousPrice || 0) * item.quantity)
        }
        if(item.monthlyService && !item.paymentDelay) {
            totalMonthly = totalMonthly + (item.price * item.quantity)
        }
        if(item.paymentDelay) {
            totalFixed = totalFixed + (item.price * item.quantity)
        }
    }

    return {totalFixed, totalMonthly, totalFixedPrevious}
}

export const QuoteProvider: React.FunctionComponent = ({children}) => {
    const reducer = (quote: Quote, action: IQuoteAction) => {
        let state = quote || initQuote
        const itemIndex = quote.items.findIndex(i => i.id === action.data.id)

        switch (action.type) {
            case QuoteAction.addItem:
                let finalItems = [...quote.items]
               
                state = {
                    ...quote,
                    items: itemIndex !== -1 ? finalItems : [...finalItems, action.data]
                }
                break
            case QuoteAction.changeItem:
                if(itemIndex !== -1) quote.items[itemIndex] = action.data
                state = {
                    ...quote,
                    items: quote.items
                }
                break
            case QuoteAction.removeItem:
                state = {
                    ...quote,
                    items: quote.items.filter(i => i.id !== action.data.id)
                }
                break
            case QuoteAction.setCoupon:
                state = {
                    ...quote,
                    coupon: action.data.coupon
                }
                break
            case QuoteAction.setForm:
                state = {
                    ...quote,
                    form: action.data.form,
                    formDirty: true
                }
                break
            case QuoteAction.setIsFormValid:
                state = {
                    ...quote,
                    isFormValid: action.data.isFormValid
                }
                break
            case QuoteAction.setQuoteNotification:
                state = {
                    ...quote,
                    quoteNotification: action.data.quoteNotification
                }
                break
            case QuoteAction.setUseType:
                state = {
                    ...quote,
                    useType: action.data.useType || 'internal'
                }
                break
            case QuoteAction.setCodeType:
                state = {
                    ...quote,
                    codeType: action.data.codeType || 'sdi'
                }
                break
            default:
                state = {
                    ...quote
                }
                break
        }

        const total = getTotal(state.items)

        return {
            ...state,
            totalFixed: total.totalFixed,
            totalMonthly: total.totalMonthly,
            totalFixedPrevious: total.totalFixedPrevious,
        }
    }

    const [quote, dispatch] = useReducer(reducer, initQuote)

    return <QuoteContext.Provider value={{dispatch, quote}}>{children}</QuoteContext.Provider>
}
