import hrLang from './lang/it/all'

interface Itranslations {
    [s:string]:{ [s: string]: string }
}

export enum Language {
    HR = 'hr',
}

export const translations:Itranslations = {
    [Language.HR]: hrLang,
}
