import React, {useContext} from 'react'
import {Checkbox, Typography} from '@material-ui/core'
import CircleChecked from '@material-ui/icons/CheckCircleOutline'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import useStyles from '../FooterStyles'
import {Quote} from '../../../../services/quote/QuoteProvider'
import I18nContext from '../../../../services/I18n/I18nContext'
import {currency} from '../../../containers/Services/components/ServiceItem'

interface IFixedCalculationProps {
    showAdditionalFooter: boolean,
    quote: Quote
}

const FixedCalculation = (props: IFixedCalculationProps) => {

    const classes = useStyles()
    const {translate} = useContext(I18nContext)
    const {quote} = props

    const basicService = quote.items.find(i => i.id === 'basicService1')
    const basicService2 = quote.items.find(i => i.id === 'basicService2')

    return (
        <div className={classes.fixedCalculationRoot}>
            {
                quote.totalFixedPrevious > 0 && (
                    <Typography className={classes.previousPriceLabel}>
                        {quote.totalFixedPrevious.toFixed(2)} {currency}
                    </Typography>
                )
            }
            <div className={classes.row}>
                <Typography className={classes.label}>
                    {translate('now')}
                </Typography>
                {
                    quote.totalFixed > 0 && (
                        <Typography className={classes.mainPrice}>
                            {quote.totalFixed.toFixed(2)} {currency}
                        </Typography>
                    )
                }
                {
                    quote.totalFixed > 0 && (
                        <Typography className={classes.secondaryLabel}>
                            {translate('pdv')}
                        </Typography>
                    )
                }
            </div>
            {props.showAdditionalFooter && (
                <>
                    <div className={classes.row}>
                        <Checkbox
                            className={classes.actionCheckbox}
                            color='primary'
                            checked={true}
                            icon={<CircleUnchecked/>}
                            checkedIcon={<CircleChecked/>}
                        />
                        <div className={classes.additionalActionWrapper}>
                            <Typography className={classes.additionalActionMessage}>
                                {basicService?.title}
                            </Typography>
                            <Typography className={classes.additionalActionPrice}>
                                {basicService ? (basicService.quantity * basicService.price).toFixed(2) : ''} {currency}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.row}>
                        <Checkbox
                            className={classes.actionCheckbox}
                            color='primary'
                            checked={true}
                            icon={<CircleUnchecked/>}
                            checkedIcon={<CircleChecked/>}
                        />
                        <div className={classes.additionalActionWrapper}>
                            <Typography className={classes.additionalActionMessage}>
                            {basicService2?.title}
                            </Typography>
                            <Typography className={classes.additionalActionPrice}>
                                {basicService2 ? (basicService2.quantity * basicService2.price).toFixed(2) : ''} {currency}
                            </Typography>
                        </div>
                    </div>
                </>
            )}
        </div>
    )

}

export default FixedCalculation
