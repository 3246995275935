import axios from 'axios'

declare var process : {
    env: {
        REACT_APP_API_URL: string
    }
}

const saveQuote = async (quote: any) => {

    const resp = await axios.post(process.env.REACT_APP_API_URL, quote, {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json'
        },
    });

    if (!resp) {
        throw new Error('Error with applying quote')
    }

    return resp.data
};

export {saveQuote}
